import React, { useMemo, useState } from 'react'
import cardBgImg from '../../../assets/membership/card-bg.svg'
import styled from 'styled-components'
import featureCardImg1 from '../../../assets/membership/featureCards/1.png'
import featureCardImg2 from '../../../assets/membership/featureCards/2.png'
import featureCardImg3 from '../../../assets/membership/featureCards/3.png'
import featureCardImg4 from '../../../assets/membership/featureCards/4.png'
import featureCardImg5 from '../../../assets/membership/featureCards/5.png'
import featureCardImg6 from '../../../assets/membership/featureCards/6.png'
import { ReactComponent as MembershipIcon } from '../../../assets/decorate-my-room/membership.svg'
import dynamic from 'next/dynamic'
import FeatureModal from '../../DesignerModals/FeatureModal'

const Carousel = dynamic(() => import('3d-react-carousel').then(res => res.Carousel), {
  ssr: false
})

const SliderContainer = styled.div`
  height: 208px;

  .react-3d-carousel {
    width: 150%;
    margin-left: -25%;
  }

  .slider-single.preactive {
    left: -45% !important;
  }

  .slider-single.proactive {
    left: 45% !important;
  }

  .slider-single.active {
    width: 120% !important;
    margin-left: -10%;
  }

  @media (max-width: 800px) {
    height: 136px;
  }
`

const SlideInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const FeatureCardElement = styled.div`
  width: 480px;
  height: 180px;
  background: ${props => props.backgroundColor};
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;

  @media (max-width: 800px) {
    width: 303px;
    height: 136px;
  }
`

const CardBgImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
`

const CardFeatureImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  pointer-events: none;

  @media (max-width: 800px) {
    object-fit: contain;
    margin-left: -20px;
  }
`

const CardTextContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  color: #000000;

  & span:first-of-type {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  & span:nth-child(2) {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 10px;
  }

  & span:nth-child(3) {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  @media (max-width: 800px) {
    & span:first-of-type {
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 6px;
    }

    & span:nth-child(2) {
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      margin-bottom: 8px;
    }

    & span:nth-child(3) {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
    }
  }
`

const PaginationContainer = styled.div`
  width: 100%;
  padding: 13px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
`

const PaginationDot = styled.div`
  width: 10px;
  height: 10px;
  background: ${props => (props.active ? '#FF5E6D' : '#CECECE')};
  border-radius: 50%;
`

const Header = styled.div`
  padding-left: 18px;
  padding-bottom: 13px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & img {
    width: 31px;
    height: auto;
  }

  & span {
    margin-left: 11px;
    font-weight: 700;
    font-size: 24px;
    color: white;
  }
`

const FeatureCard = ({ firstLine, secondLine, thirdLine, backgroundColor, image, modalId, setFeatureModalData }) => {
  return (
    <FeatureCardElement onClick={() => setFeatureModalData(modalId)} backgroundColor={backgroundColor}>
      <CardBgImage src={cardBgImg} alt="" />
      <CardFeatureImage src={image} alt="" />
      <CardTextContainer>
        <span>{firstLine}</span>
        <span>{secondLine}</span>
        <span>{thirdLine}</span>
      </CardTextContainer>
    </FeatureCardElement>
  )
}

const featureCards = [
  {
    backgroundColor: '#F9DBE6',
    firstLine: 'Unlimited Usage of',
    secondLine: 'All Furniture',
    thirdLine: 'Unlock all the premium features',
    image: featureCardImg1,
    modalId: 'furniture'
  },
  {
    backgroundColor: '#CECEFB',
    firstLine: 'Unlimited Uploads of',
    secondLine: 'MyRoom',
    thirdLine: 'Unlock all premium features',
    image: featureCardImg2,
    modalId: 'room'
  },
  {
    backgroundColor: '#F6CFCD',
    firstLine: 'Unlimited Uploads of',
    secondLine: 'MyDecor Items',
    thirdLine: 'Unlock all premium features',
    image: featureCardImg3,
    modalId: 'decor'
  },
  {
    backgroundColor: '#E5F0DB',
    firstLine: 'Unlimited Space for',
    secondLine: 'Design Drafts',
    thirdLine: 'Unlock all premium features',
    image: featureCardImg4,
    modalId: 'designDrafts'
  },
  {
    backgroundColor: '#F6CEEF',
    firstLine: 'Design with Amazing',
    secondLine: 'Animated Filters',
    thirdLine: 'Unlock all premium features',
    image: featureCardImg5,
    modalId: 'filter'
  },
  {
    backgroundColor: '#D1E9FD',
    firstLine: '',
    secondLine: 'And Many More ...',
    thirdLine: 'Unlock all the premium features',
    image: featureCardImg6,
    modalId: 'featureList'
  }
]

const Slider = React.memo(({ currentSlide, setCurrentSlide, setFeatureModalData }) => {
  const slides = useMemo(() => {
    return featureCards.map((card, index) => (
      <SlideInner key={index}>
        <FeatureCard key={index} firstLine={card.firstLine} secondLine={card.secondLine} thirdLine={card.thirdLine} backgroundColor={card.backgroundColor} image={card.image} modalId={card.modalId} setFeatureModalData={setFeatureModalData} />
      </SlideInner>
    ))
  }, [setFeatureModalData])

  return <Carousel slides={slides} autoplay={false} interval={3000} currentSlide={currentSlide} onSlideChange={setCurrentSlide} />
})

export const FeatureSlider = ({ showTitle = true }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [featureModalData, setFeatureModalData] = useState(null)

  return (
    <>
      {showTitle && (
        <Header>
          <MembershipIcon style={{ width: 31 }} />
          <span>DecorMatters</span>
        </Header>
      )}
      <SliderContainer>
        <Slider currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} setFeatureModalData={setFeatureModalData} />
      </SliderContainer>
      <PaginationContainer>
        {featureCards.map((_, index) => (
          <PaginationDot onClick={() => setCurrentSlide(index)} key={index} active={currentSlide === index} />
        ))}
      </PaginationContainer>
      {!!featureModalData && <FeatureModal data={featureModalData} setData={setFeatureModalData} />}
    </>
  )
}
