import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  setModalOpen,
  setModalClose
} from '../redux/designerReducers/designerReducer'

const useModal = show => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (show) dispatch(setModalOpen())
    else dispatch(setModalClose())
  }, [dispatch, show])
}

export default useModal
