import Image from 'next/image'
import styled from 'styled-components'

export const MembershipBlockWrapper = styled.div`
  width: 100%;
  padding-top: 80px;
  padding-bottom: 36px;
  padding-left: 140px;
  padding-right: 140px;
  background: linear-gradient(264.55deg, #783cd9 8.71%, #5824ac 91.29%);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @media (max-width: 1200px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  @media (max-width: 800px) {
    padding-top: 36px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  @media (min-width: 1728px) {
    padding-left: 222px;
    padding-right: 222px;
  }
`

export const MobileMembershipBlockWrapper = styled(MembershipBlockWrapper)`
  align-items: flex-start;
  padding: 0;
  margin: 0;
  overflow: hidden;
`

export const BackgroundImageDesktop = styled(Image)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: block;
  object-fit: cover;

  @media (max-width: 800px) {
    display: none;
    opacity: 0;
    object-fit: cover;
  }
`

export const BackgroundImageMobile = styled(Image)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: none;
  opacity: 0;

  @media (max-width: 800px) {
    display: block;
    opacity: 1;
  }
`

export const Title = styled.h2`
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 0;
  z-index: 2;

  @media (max-width: 800px) {
    font-size: 20px;
    line-height: 24px;
  }
`

export const MobileContentContainer = styled.div`
  width: 100%;
  z-index: 2;
`

export const MobileTitleContainer = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 24px;
`

export const MobileTitle = styled.h2`
  font-weight: 700;
  font-size: 34px;
  color: white;
  padding: 0;
  margin: 0;
`

export const MobileNote = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 132.8%;
  color: rgba(255, 255, 255, 0.6);
  width: 100%;
  text-align: center;
  z-index: 2;
  padding: 0;
  margin-top: 16px;
  margin-bottom: 20px;
`

export const MobileLargeGradientButton = styled.button`
  flex: 1 1 auto;
  height: 44px;
  border-radius: 25px;
  background: linear-gradient(270deg, #ff9d74 0%, #ff5e6d 37.4%, #ff4f7e 74.69%, #ff6d90 100%);
  border: 0;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
`

export const Subtitle = styled.p`
  max-width: 750px;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 0;
  margin-top: 24px;
  margin-bottom: 40px;

  z-index: 2;

  @media (max-width: 800px) {
    font-size: 14px;
    line-height: 150%;
    padding-left: 18px;
    padding-right: 18px;
    margin-top: 16px;
  }
`

export const Grid = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 2;
  gap: 140px;

  @media (max-width: 1200px) {
    gap: 0px;
    width: 100%;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 0px;
  }
`

export const GridSection = styled.div`
  flex: 1;
  width: 100%;
  max-width: ${props => props.maxWidth || '600px'};
  /*margin-top: 64px;*/
  /*text-align: center;*/

  @media (max-width: 1200px) {
    margin: 0;
    width: 100vw;
    max-width: 100vw;
    /*margin-top: 20px;*/
    text-align: ${props => (props.mobileCenter ? 'center' : 'left')};
    display: ${props => (props.mobileHide ? 'none' : 'block')};
  }
`

export const GridSectionTitle = styled.div`
  display: flex;
  align-items: flex-end;

  @media (max-width: 1200px) {
    margin: 0 24px;
  }

  @media (max-width: 800px) {
    margin: 0;
    background: rgba(255, 255, 255, 0.15);
    display: inline-flex;
    padding: 5px 19px 5px 11px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;

    & span {
      width: 18px !important;
      height: 17px !important;
    }
  }
`

export const GridSectionTitleText = styled.h3`
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #ffffff;
  margin: 0;
  padding: 0;
  margin-left: 9px;

  @media (max-width: 800px) {
    font-size: 16px;
    line-height: 16px;
  }
`

export const PrivilegeList = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin-top: 24px;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1200px) {
    flex-wrap: wrap;
    margin-left: 24px;
    margin-right: 24px;
    /*
    margin-top: 25px;
    
    width: 100vw;
    overflow: auto;
    padding-left: 10px;
    padding-right: 30px;
    box-sizing: border-box;
    */
  }
`

export const Privilege = styled.button`
  position: relative;
  height: 70px;
  width: 70px;
  flex-shrink: 0;
  flex-grow: 0;
  background: #fff;
  border-radius: 10px;
  flex-shrink: 0;
  font-family: Helvetica Neue;
  font-size: 11px;
  font-weight: 700;
  line-height: 13px;
  padding: 9px 5px;
  box-sizing: border-box;
  background-position: bottom right;
  background-size: cover;
  color: #0c0750;
  box-shadow: 0px 2px 6px 0px #ff5e6d33;
  border: 0;
  display: flex;
  text-align: left;
  cursor: pointer;
  margin-bottom: 20px;

  &:not(:last-child) {
    margin-right: 14px;
  }

  @media only screen and (max-width: 1200px) {
    height: 100px;
    width: 100px;
    font-family: Helvetica Neue;
    font-size: 14px;
    padding: 10px;
    line-height: 17px;
  }
`

export const CancelText = styled.p`
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 45px;
  text-align: center;
  @media (max-width: 1200px) {
    text-align: center;
  }
  /*
  @media (max-width: 800px) {
    margin-top: 18px;
    z-index: 1;
    font-size: 12px;
    text-align: center;
  }
  */
`

export const VideoContainer = styled.div`
  position: relative;
  width: 268px;
  height: 540px;

  & video {
    position: absolute;
    top: 10px;
    left: 0;
    width: 268px;
    height: 540px;
    border-radius: 30px;
  }

  & img {
    position: absolute;
    top: 0;
    left: 0;
  }
`
