import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import styled from 'styled-components'
import { logEvent } from '../../utils/firebase'
import { MobileLargeGradientButton } from './MembershipLandingAdvert.styles'
import checkmarkIcon from '../../assets/checkmark.svg'

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
  margin: 0 16px;
`

const Package = styled.button`
  flex: 1 1 auto;
  width: 85%;
  position: relative;
  color: white;
  border-radius: 9px;
  border: none;
  background: rgba(255, 255, 255, 0.2);
  outline: none;
  padding: 23px 16px;
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const MainPackage = styled(Package)`
  flex: 1 1 auto;
  width: 100%;
  border: 2px solid #ff5e6d;
  padding: 24px 20px;
`

const Price = styled.span`
  color: #fff;
  font-size: 20px;
  font-weight: 700;
`

const MainPrice = styled(Price)``

const Subtitle = styled.span`
  /*
  font-family: Helvetica Neue;
  font-size: ${({ isSale }) => (isSale ? '24px' : '20px')};
  font-weight: ${({ isSale }) => (isSale ? '500' : '400')};
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  color: ${({ isSale }) => (isSale ? '#FF5E6D' : '#7e7c8d')};

  @media only screen and (max-width: 600px) {
    font-size: 11px;
  }
  */
`

const DiscountBanner = styled.span`
  position: absolute;
  top: -10px;
  right: 24px;
  box-sizing: border-box;
  height: 30px;
  width: 116px;
  color: #fff;

  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  background: linear-gradient(270deg, #ff9d74 0%, #ff5e6d 37.4%, #ff4f7e 74.69%, #ff6d90 100%);
`

const Description = styled.span`
  font-family: Helvetica Neue;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: center;
  color: #fff;
  opacity: 0.65;
  display: block;
  margin: 20px auto 0;

  @media only screen and (max-width: 600px) {
    font-size: 12px;
  }
`

const CurrentPlan = styled.span`
  /*
  font-family: Helvetica Neue;
  font-size: 16px;
  font-weight: 700;
  color: #ff5e6d;
  position: ${({ isMain }) => (isMain ? 'static' : 'absolute')};
  top: 5px;

  @media only screen and (max-width: 600px) {
    font-size: 11px;
  }
  */
`

const Discount = styled.span`
  /*
  display: block;
  height: 15px;
  font-family: Helvetica Neue;
  font-size: 16px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0px;
  color: #ff5e6d;
  margin-bottom: 23px;

  @media only screen and (max-width: 600px) {
    margin-bottom: 17px;
    font-size: 12px;
  }
  */
`

const RadialOff = styled.div`
  position: absolute;
  top: calc(50% - 10px);
  right: 20px;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-radius: 50%;
`

const RadialOn = styled(RadialOff)`
  border: 2px solid rgb(255, 94, 109);
  background: rgb(255, 94, 109);
  background-image: url(${checkmarkIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px;
`

const formatPrice = raw => {
  return (raw * 0.01).toFixed(2)
}

const MobileMembersipPackages = ({ packages, message, onSelect, joinStyle = {} }) => {
  if (!packages?.length) return null

  const me = useSelector(state => state.profile.me)
  const currentMembershipId = me?.membership?.id
  const isYearly = currentMembershipId === packages[0].id

  const [selected, setSelected] = useState(0)

  const expirationDate = useMemo(() => {
    if (!me?.subscriberRecord?.subscriptions) return null

    return `Expires on ${moment(me?.subscriberRecord?.subscriptions?.[currentMembershipId]?.expires_date).format('MMM D, YYYY')}`
  }, [me?.subscriberRecord?.subscriptions, currentMembershipId])

  //const sorted = [packages[2], packages[0], packages[1]]
  const sorted = [packages[0], packages[2]]

  return (
    <>
      <Root>
        {sorted.map((item, index) => {
          const { id, metadata, name, price } = item
          const isCurrent = currentMembershipId === id
          if (item == 'empty')
            return (
              <Package
                key={`${id}_${name}`}
                onClick={() => {
                  logEvent('secondary_subscription_package_click', {})
                  // onSelect(item)
                  setSelected(index)
                }}
              ></Package>
            )
          return metadata.promote !== 'true' ? (
            <Package
              key={`${id}_${name}`}
              onClick={() => {
                logEvent('secondary_subscription_package_click', {})
                // onSelect(item)
                setSelected(index)
              }}
            >
              {isCurrent && <CurrentPlan>Current Plan</CurrentPlan>}
              <span>{!isCurrent && metadata?.offPercent && <Discount>{metadata.offPercent}</Discount>}</span>
              <Price>
                ${formatPrice(price.unit_amount)} {name}
              </Price>

              {index === selected ? <RadialOn /> : <RadialOff />}
              {/* <Subtitle isSale={metadata.convert}>{metadata.convert ? `(${metadata.convert})` : 'Standard'}</Subtitle> */}
            </Package>
          ) : (
            <MainPackage
              key={`${id}_${name}`}
              onClick={() => {
                logEvent('main_subscription_package_click', {})
                // onSelect(item)
                setSelected(index)
              }}
            >
              <DiscountBanner>{metadata.offPercent}</DiscountBanner>
              {isCurrent && <CurrentPlan isMain>Current Plan</CurrentPlan>}

              <MainPrice>
                ${formatPrice(price.unit_amount)} {name}
              </MainPrice>
              <Subtitle>({metadata.convert})</Subtitle>
              {index === selected ? <RadialOn /> : <RadialOff />}
              {/* <UpgradeButton
                onClick={() => {
                  logEvent('main_subscription_package_click', {})
                  onSelect(sorted[1]) // yearly
                }}
              >
                {!currentMembershipId ? 'Subscribe Now' : 'Upgrade'}
              </UpgradeButton> */}
            </MainPackage>
          )
        })}
      </Root>
      <Description>{currentMembershipId ? expirationDate : message}</Description>
      <div style={{ margin: '0 36px', display: 'flex', ...joinStyle }}>
        <MobileLargeGradientButton
          onClick={() => {
            onSelect(sorted[selected])
          }}
        >
          Join Now
        </MobileLargeGradientButton>
      </div>
    </>
  )
}

export default MobileMembersipPackages
